.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
}

.App-header {
}

.App-link {
}
